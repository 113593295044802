<template>
  <DialogFormWrapper>
    <template #form>
      <v-form
        id="confirmBdo"
        ref="confirmBdo"
        class="custom-form pt-6"
        @submit.prevent="confirm"
      >
        <v-col cols="12">
          <v-text-field
            v-model="confirmation.reportedWeight"
            outlined
            validate-on-blur
            type="number"
            disabled
            label="Raportowana waga [T]"
            :rules="[rules.required]"
            @wheel="$event.target.blur()"
          />
        </v-col>
        <v-col cols="12">
          <v-textarea
            v-model="confirmation.remarks"
            outlined
            :rows="3"
            auto-grow
            label="Uwagi"
            placeholder="Wpisz uwagi"
          />
        </v-col>

        <v-col class="my-0 py-0 pl-4">
          <Warning
            v-if="weightsDontMatch"
            message="Wagi w zleceniu i w karcie nie zgadzają się."
          />
          <Warning
            v-if="codesDontMatch"
            message="Kody odpadu w zleceniu i w karcie nie zgadzają się."
          />
        </v-col>
      </v-form>
    </template>
    <template #submit>
      <v-btn
        color="primary"
        class="base-hover"
        :loading="isProcessing"
        type="submit"
        :disabled="weightsDontMatch || codesDontMatch"
        form="confirmBdo"
      >
        Potwierdź przejęcie
      </v-btn>
    </template>
  </DialogFormWrapper>
</template>

<script>
import DialogFormWrapper from './Partials/DialogFormWrapper'
import rules from '../../utils/validators'
import { mapState, mapActions } from 'vuex'
import afterFormSubmitted from '../../mixins/afterFormSubmitted'

export default {
  components: {
    DialogFormWrapper,
  },
  mixins: [afterFormSubmitted],
  data () {
    return {
      rules,
      confirmation: {
        remarks: null,
        reportedWeight: null,
      }
    }
  },
  computed: {
    ...mapState({
      item: state => state.layout.dialog.item,
      isProcessing: state => state.bdoCard.isProcessing
    }),
    weightsDontMatch () {
      const { order, reportedWeight } = this.item
      return order?.reportedWeight !== reportedWeight
    },
    codesDontMatch () {
      const { order, reportedDebrisType } = this.item
      return reportedDebrisType?.code !== order?.reportedDebrisType?.code
    }
  },
  created () {
    this.confirmation.reportedWeight = this.item?.reportedWeight
  },
  methods: {
    ...mapActions({
      confirmBdoCard: 'bdoCard/confirmBdoCard',
    }),
    confirm () {
      if (this.$refs.confirmBdo.validate()) {
        this.confirmBdoCard(this.confirmation)
          .then(() => {
            this.afterFormSubmitted('Potwierdzono przejęcie odpadów')
          })
      }
    }
  }
}
</script>
